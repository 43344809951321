import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
//CSS
import './DashboardAdminManageBookings.css';
//Redux
import { connect } from 'react-redux';
import { getAllUsers } from '../../actions/users';
//Components
import Booking from './DashboardBookingItem';

const DashboardAdminManageBookings = ({ users: { users }, getAllUsers }) => {

  const [selectedUser, setSelectedUser] = useState(null);

  let sortedUsers = users.sort(function(a,b){
    return a.name.localeCompare(b.name);
})

  let filteredUser = users.filter((user) => {
    if (user.name === selectedUser) {
      return user;
    }
  })

  useEffect(() => {
    getAllUsers();
  }, [])

  return (
    <div className="dashboardAdminManageBookings">
      <div className="searchUserBookings">
        <label htmlFor="search">Filter by user: </label>
        <select name="search" onChange={(e) => {
          if (e.target.value === 'Please select a user') {
            setSelectedUser();
          } else {
            setSelectedUser(e.target.value.trim())
          }
        }}>
          <option>Please select a user</option>
          {users.map(user => {
            return <option>{user.name}</option>
          })}
        </select>
      </div>
      <div className="dashboardAdminManageBookings__list">
        {selectedUser && filteredUser.length > 0 && filteredUser.map(user => {
          return (
            <Fragment>
              {user.bookings.map(booking => {
                return <Booking bookedBy={user.name} adminPage key={booking._id} item={booking} updateUsers={getAllUsers} />
              })}
            </Fragment>
          )
        })}
        {!selectedUser && users.map(user => {
          return (
            <Fragment>
              {user.bookings.map(booking => {
                return <Booking bookedBy={user.name} adminPage item={booking} updateUsers={getAllUsers} />
              })}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

DashboardAdminManageBookings.propTypes = {
  users: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  users: state.users,

})

export default connect(mapStateToProps, { getAllUsers })(DashboardAdminManageBookings)
