import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, NavLink, Redirect } from 'react-router-dom'
import { loadUser } from '../../actions/auth'
import { deleteBooking } from '../../actions/course';
import DashboardHeaderNavItem from './DashboardHeaderNavItem';
import './Dashboard.css';
import DashboardBookings from './DashboardBookings';
import DashboardAdmin from './DashboardAdmin';


const Dashboard = ({ auth, loadUser }) => {

  useEffect(() => {
    loadUser();
  }, [loadUser]);


  if (auth.isAuthenticated) {
    return (

      <div className="dashboard">
        <div className="dashboard__header">
          <div className="dashboard__header-nav">
            <NavLink to="/dashboard/bookings" activeClassName="active-dashboard-link">
              <DashboardHeaderNavItem title="My Bookings" />
            </NavLink>
            {auth.user.isAdmin && !auth.isLoading && (<NavLink to="/dashboard/admin" activeClassName="active-dashboard-link">
              <DashboardHeaderNavItem title="Admin Tools" />
            </NavLink>)}
          </div>
        </div>

        <div className="dashboard__body">

          <Route path="/dashboard/bookings" component={DashboardBookings} />
          <Route path="/dashboard/admin" component={DashboardAdmin} />

        </div>


      </div >

    )
  } else {
    return <Redirect to="/" />
  }


}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { loadUser, deleteBooking })(Dashboard);
