import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/auth';
import { Redirect } from 'react-router-dom';
import './DashboardBookings.css';
import DashboardBookingItem from './DashboardBookingItem';

const DashboardBookings = ({ auth: { loading, isAuthenticated, user }, loadUser }) => {



  let bookings = user.bookings;

  useEffect(() => {
    loadUser();
  }, []);

  if (isAuthenticated) {

    return (
      <div className="dashboardBookingsContainer">

        {!loading && bookings.length > 0 ? bookings.map((item) => {
          return <DashboardBookingItem key={item._id} item={item} />
        }) : 'No Bookings'}

        <p>For any further enquiries or cancellations, please email claire.craig@orangeboxtraining.com or call the office on 01429717000 - option 3.</p>
      </div>
    )

  } else {
    return <Redirect to="/login" />
  }
}

DashboardBookings.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { loadUser })(DashboardBookings)
