import React from 'react'

const CourseOverview = ({ courseType }) => {

  let firstAidAtWorkOV =
    <>
      <h3><strong>Course Overview</strong></h3>
      <p><span className="courseOverviewTitle">First Aid at Work, Level 3 (RQF)</span><br /><br />Employers have to risk assess their workplace first aid requirements and the 3-day First Aid at Work (FAW) qualification is one of the statutory qualifications for this purpose.<br />
Successful completion will result in the knowledge and skills that have been identified by the HSE for the management of emergency incidents and the treatment of illness and injuries in the workplace.</p>
    </>;

  let emergencyFirstAidAtWorkOV =
    <>
      <h3><strong>Course Overview</strong></h3>
      <p><span className="courseOverviewTitle">Level 3 Award in Emergency First Aid at Work(QCF)</span><br /><br />Employers have to risk assess their workplace first aid requirements and the 1 - day Emergency First Aid at Work (EFAW) qualification is one of the statutory qualifications for this purpose.<br /><br />
      If the risk assessment indicates that employees may be faced with life - threatening incidents where they have to manage the casualty for up to 10 minutes, or deal with very minor incidents that will not require medical assistance then the EFAW is the course that meets this need.
</p>
    </>;


  let paediatricFirstAid =
    <>
      <h3><strong>Course Overview</strong></h3>
      <p><span className="courseOverviewTitle">Level 3 Award in Paediatric First Aid (RQF)</span><br /><br />This qualification is designed for workers caring for children in the absence of their parents. This qualification meets Ofsted requirements for those working in early years settings and uses the criteria for effective paediatric first aid training found in Appendix 1 of The Early Years Foundation Stage (EYFS) Practice Guidance document. <br /><br />
      Current EYFS statutory framework requires at least one person who has a current paediatric first aid certificate to be on the premises or outings at all times, when children are present.
</p>
    </>;



  let basicLifeSupport =
    <>
      <h3><strong>Course Overview</strong></h3>
      <p><span className="courseOverviewTitle">
        Basic Life Support and Safe Use of an Automated External Defibrillator</span><br /><br />Modern Automated External Defibrillators (AED’s) are becoming more common, are straightforward to use and save lives.This Basic Life Support qualification is designed for individual to use AED’s confidently and competently.
</p>
    </>;

  switch (courseType) {
    case "Paediatric First Aid at Work":
      return paediatricFirstAid;
    case "Emergency First Aid at Work":
      return emergencyFirstAidAtWorkOV;
    case "First Aid at Work":
    case "First Aid at Work Requal":
      return firstAidAtWorkOV;
    case "Basic Life Support":
      return basicLifeSupport;
    default:
      return (
        <>
          <h3>Course Overview</h3>
          <p>There are no details currently available for this course.</p>
        </>
      )
  }
}

export default CourseOverview
