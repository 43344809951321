import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCategory, clearCurrentCategory } from '../../../actions/course';
import SingleCourse from './SingleCourse';
import Spinner from '../../Spinner';
import './SingleCategory.css'
import OBImg from '../../../img/Orangebox-banner.jpeg';
import Hero from './Hero';

const SingleCategory = ({ getCategory, clearCurrentCategory, match, auth, course: { loading, currentCategory, courses } }) => {

  useEffect(() => {

    getCategory(match.params.id);

    return () => {
      clearCurrentCategory();
    }
  }, [])

  //const [date, setDate] = useState();
  const [type, setType] = useState();

  if (!loading && currentCategory) {

    const filteredCourses = courses.filter(course => {
      if (course.category == currentCategory.name && new Date(course.startDate).getTime() > (new Date().getTime() - 1000 * 60 * 60 * 24)) {
        return course;
      }

    })

    /*
        const coursesByDate = filteredCourses.filter(course => {
          if (new Date(course.startDate).toLocaleDateString() == date) {
            return course;
          }
        })
    */

    let coursesByType = filteredCourses.filter(course => {
      if (type === 'Please select a course type') {
        return course
      }
      if (course.name === type) {
        return course;
      }
    })


    return (
      <Fragment>
        <Hero title={currentCategory.name} bgImage={OBImg} typeSearch={currentCategory.name === 'First Aid' ? true : false} setType={setType} bgOverlay={false} />
        <div className="singleCategory__body">

          {!type && filteredCourses.map(course => {
            return (
              <Fragment>
                <SingleCourse key={course._id} imgPath={OBImg} course={course} auth={auth} />
              </Fragment>
            )
          })}

          {coursesByType && coursesByType.length > 0 && coursesByType.map(course => {

            return (
              <Fragment>
                <SingleCourse key={course._id} imgPath={OBImg} course={course} auth={auth} />
              </Fragment>
            )
          })

          }

          {coursesByType && coursesByType.length < 1 && type && (
            <div className="singleCategory__body__no-courses">
              <p>No Courses by this type are currently scheduled. Please check back at a later date or contact us for more information.</p>
              <button className="btn btn-gradient" onClick={() => setType(null)}> View All</button>
            </div>
          )}

          {filteredCourses.length < 1 ? <div>No courses for this category have been scheduled yet.</div> : null}
        </div>
      </Fragment >
    )
  } else {
    return <Spinner />
  }


}

SingleCategory.propTypes = {
  getCategory: PropTypes.func.isRequired,
  clearCurrentCategory: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth
})

export default connect(mapStateToProps, { getCategory, clearCurrentCategory })(SingleCategory)
