export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_CATEGORY = 'GET_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';
export const CLEAR_CATEGORY_ERROR = 'CLEAR_CATEGORY_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY_ERROR';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const POST_COURSE = 'POST_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const CREATE_BOOKING = 'CREATE_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const UPDATE_USER = 'UPDATE_USER';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const DISABLE_COURSE = 'DISABLE_COURSE';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';