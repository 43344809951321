import React from 'react'
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import DropdownItem from './NavbarDropdownItem';
import './NavbarDropdown.css';
import logoutIcon from '../../../img/logout.svg';
import dashboardIcon from '../../../img/dashboard.svg';

const NavbarDropdown = ({ logout, auth: { isAuthenticated, user }, toggleDropdown }) => {

  const logoutBtn = (
    <NavLink exact to="/" onClick={() => { logout(); toggleDropdown(); }}>
      <li>Logout</li>
      <img src={logoutIcon} alt="logout-icon" />
    </NavLink>
  );

  const loginBtn = (
    <NavLink exact to="/login" activeClassName="active-link">
      <li>Login</li>
    </NavLink>
  );

  return (
    <div className="navDropdown">

      <DropdownItem toggleDropdown={toggleDropdown}>
        <Link to="/dashboard">
          <li>Dashboard</li>
          <img src={dashboardIcon} alt="dashboard-icon" />
        </Link>
      </DropdownItem>
      <DropdownItem toggleDropdown={toggleDropdown}>
        {isAuthenticated ? logoutBtn : loginBtn}
      </DropdownItem>




    </div>
  )
}

NavbarDropdown.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logout })(NavbarDropdown);
