import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCategories } from '../../../actions/course';

const CourseCategoriesOptions = ({ getCategories, course: { categories } }) => {

  useEffect(() => {
    getCategories();
  }, [])

  return (
    <Fragment>
      <option defaultValue>Please select a course category</option>
      {categories.map(category => {
        return <option key={category._id}>{category.name}</option>;
      })}
    </Fragment>
  )
}

CourseCategoriesOptions.propTypes = {
  getCategories: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  course: state.course
});

export default connect(mapStateToProps, { getCategories })(CourseCategoriesOptions);
