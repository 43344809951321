import React, { useState } from 'react'
import './DashboardAdminItem.css';
import PlusImg from '../../img/plus.svg';
import MinusImg from '../../img/minus.svg';

const DashboardAdminItem = props => {

  const [button, setButton] = useState(PlusImg);
  const [height, setHeight] = useState('0px');
  const [display, setDisplay] = useState('none');
  const [padding, setPadding] = useState('0px');

  const toggleHeight = () => {
    if (height === '0px' && display === 'none') {
      setButton(MinusImg);
      setHeight('auto');
      setTimeout(() => {
        setDisplay('flex');
        setPadding('20px');
      }, 100)

    } else {
      setButton(PlusImg);
      setPadding('0px');
      setDisplay('none');
      setHeight('0px');
    }
  }


  return (
    <div className={`dashboardAdminItem ${props.disabled ? 'disabled' : ''}`}>
      <div className="dashboardAdminItemHeader" onClick={toggleHeight}>
        <p>{props.title}</p>
        <img src={button} alt="button" />
      </div>
      <div className="dashboardAdminItemBody" style={{ height: height }}>
        <div className="dashboardAdminItemBodyComponent" style={{ display: display, padding: padding }}>
          {props.children}
        </div>
      </div>

    </div >
  )
}

export default DashboardAdminItem
