import React from 'react';
import './DashboardHeaderNavItem.css';

const DashboardHeaderNavItem = (props) => {
  return (
    <div className="dashboard__header-nav-item">
      <p>{props.title}</p>
    </div>
  )
}

export default DashboardHeaderNavItem
