import React from 'react'

import DashboardAdminItem from './DashboardAdminItem';
import './DashboardAdmin.css';
import AddCourseForm from '../Layout/Courses/AddCourseForm';
import AddCategoryForm from '../Layout/Courses/AddCategoryForm';
import DashboardAdminDeleteCourse from './DashboardAdminDeleteCourse';
import DashboardAdminDeleteCategory from './DashboardAdminDeleteCategory';
import DashboardAdminUsers from './DashboardAdminUsers'
import DashboardAdminManageBookings from './DashboardAdminManageBookings';
import DashboardAdminViewOrganisations from './DashboardAdminViewOrganisations';

const DashboardAdmin = (props) => {
  return (
    <div className="dashboardAdmin">
      <DashboardAdminItem title="Add New Course">
        <AddCourseForm />
      </DashboardAdminItem>
      <DashboardAdminItem title="Add New Category/Organisation">
        <AddCategoryForm />
      </DashboardAdminItem>
      <DashboardAdminItem title="Manage Courses">
        <DashboardAdminDeleteCourse />
      </DashboardAdminItem>
      <DashboardAdminItem title="Manage Bookings">
        <DashboardAdminManageBookings />
      </DashboardAdminItem>
      <DashboardAdminItem title="Manage Categories">
        <DashboardAdminDeleteCategory />
      </DashboardAdminItem>
      <DashboardAdminItem title="View All Users">
        <DashboardAdminUsers />
      </DashboardAdminItem>
      <DashboardAdminItem title="View Organisations">
        <DashboardAdminViewOrganisations />
      </DashboardAdminItem>

      <p style={{ marginTop: '40px', color: 'rgba(0,0,0,0.3)' }}> <a href="https://en.gravatar.com/"> Want to add a profile picture? Click here to create a free Gravatar account </a>  - Make sure you use the same email address associated with your Orangebox courses account!</p>

    </div>
  )
}

export default DashboardAdmin
