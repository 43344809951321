import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCategory, getCategories } from '../../actions/course';
import { Link } from 'react-router-dom';
import './DashboardAdminDeleteCategory.css';
import DeleteSVG from '../svg/DeleteSVG';

const DashboardAdminDeleteCategory = ({ deleteCategory, getCategories, course: { categories } }) => {
  return (
    <div className="dashboardAdminDeleteCategory">
      {categories.map(category => {
        return (
          <div className="coursesCategoryItem" style={{ backgroundColor: category.colorCode }}>
            <div className="coursesCategoryItemTitle">
              {category.name}
            </div>
            <div className="coursesCategoryItemFooter">
              <p>{category.courses.length === 0 || category.courses.length > 1 ? `${category.courses.length} Courses` : `${category.courses.length} Course`} </p>
              <Link to={`/categories/${category._id}`}>
                <button>View Category</button>
              </Link>
              <button onClick={async () => { await deleteCategory(category._id); getCategories(); }}><DeleteSVG /></button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

DashboardAdminDeleteCategory.propTypes = {
  deleteCategory: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  course: state.course
})

export default connect(mapStateToProps, { deleteCategory, getCategories })(DashboardAdminDeleteCategory)
