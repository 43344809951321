import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteCourse } from '../../../actions/course';
import './SingleCourse.css';


const SingleCourse = ({ imgPath, course: { _id, name, startDate, spaces, time, location, category, image }, auth: { user } }) => {

  /*
  let courseDate = new Date(date);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  courseDate = courseDate.toLocaleDateString(options);
*/

  return (
    <div className="courseCardWrapper" style={{ backgroundImage: `url(${image})` }}>
      <Link to={`/courses/${_id}`}>
        <div className="courseCard" key={_id} >
          <div className="courseCard__header" >
            <h5 className="courseCategoryItem">{category}</h5>
          </div>
          <div className="courseCard__body">
            <div className="courseCard_body-details">
              <h2>{name}</h2>
              <h5>{new Date(startDate).toLocaleDateString()}</h5>
            </div>
          </div>
          <div className="courseCard__footer">
            <div className="courseCard__footer__spaces">
              <h3>{spaces}</h3>
              <p>Spaces Remaining</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

SingleCourse.propTypes = {
  course: PropTypes.object.isRequired,
  deleteCourse: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

export default connect(null, { deleteCourse })(SingleCourse);
