import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { addCategory } from '../../../actions/course';

const AddCategoryForm = ({ addCategory }) => {

  const [name, setName] = useState('');
  const [colorCode, setColorCode] = useState('#ff0000');

  const formData = {
    name,
    colorCode
  }

  return (
    <div className="form-container">
      <div className="form">
        <h2>Add New Category/Organisation</h2>
        <form onSubmit={e => {
          e.preventDefault();
          addCategory(formData, `${name} added to course categories`);
          setName('');
        }}>
          <label htmlFor="name">Name:</label>
          <input type="text" name="name" onChange={e => setName(e.target.value)}></input>
          <label htmlFor="">Colour:</label>
          <input type="color" id="colorCode" name="colorCode" value={colorCode} onChange={e => setColorCode(e.target.value)} />
          <button className="btn btn-green" type="submit">Add</button>
        </form>
      </div>
    </div>
  )
}

AddCategoryForm.propTypes = {
  addCategory: PropTypes.func.isRequired
}

export default connect(null, { addCategory })(AddCategoryForm)
