import React from 'react'
import PropTypes from 'prop-types';
import './NavbarDropdownItem.css';

const NavbarDropdownItem = props => {
  return (
    <div className="navbarDropdownItem" onClick={props.toggleDropdown}>
      {props.children}
    </div>
  )
}

NavbarDropdownItem.propTypes = {

}

export default NavbarDropdownItem
