import React from 'react';
import './ContactBarItem.css';

const ContactBarItem = (props) => {
  return (
    <div className="contactBarItem">
      {props.icon ? <img src={props.icon} alt="icon" /> : ''}
      <p>{props.text}</p>
      <a href={props.link} target="_blank"><p>{props.linkText}</p></a>
    </div>
  )
}

export default ContactBarItem;
