import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCourse } from '../../../actions/course';
import CourseCategoriesOptions from './CourseCategoriesOptions';
import FirstAidImg from '../../../img/first-aid.jpeg';
import HealthSafetyImg from '../../../img/health-safety.jpg';
import FoodHygieneImg from '../../../img/food-hygiene.jpg';
import EducationImg from '../../../img/education.jpeg';
import BusinessImg from '../../../img/business.jpg';
import OBImg from '../../../img/orangebox-image.jpeg';

const AddCourseForm = ({ addCourse }) => {

  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [spaces, setSpaces] = useState('12');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('Tranquility House, Harbour Walk, Hartlepool, TS24 0UX');
  const [price, setPrice] = useState('165.00');
  const [qualification, setQualification] = useState('Level 2');
  const [category, setCategory] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [isFirstAid, setIsFirstAid] = useState(false);

  const getCourseImage = (course) => {

    switch (course) {
      case 'First Aid':
        return FirstAidImg
      case 'Health & Safety':
        return HealthSafetyImg
      case 'Food Hygiene':
        return FoodHygieneImg
      case 'Education':
        return EducationImg
      case 'Business Training':
        return BusinessImg
      default:
        return OBImg
    }

  }

  const formData = {
    name,
    startDate,
    endDate,
    spaces,
    time,
    location,
    price,
    qualification,
    category,
    image: getCourseImage(category)
  }

  return (
    <div className="form-container">
      <div className="form">
        <h2>Add New Course</h2>
        <form onSubmit={
          e => {
            e.preventDefault();
            addCourse(formData, `${name} scheduled for ${time} on ${startDate}`);
            setName('');
            setStartDate('');
            setEndDate('');
          }}>

          <label htmlFor="category">Course Category:</label>
          <select name="category" onChange={e => {
            setCategory(e.target.value)
            if(e.target.value !== 'Health & Safety' && e.target.value !== 'Education' && e.target.value !== 'Business Training' && e.target.value !== 'Food Hygiene' && e.target.value !== 'First Aid' ){
                setOrganisation(e.target.value);
            } else {
              setOrganisation(null);
            }
          }
        } required>
            <CourseCategoriesOptions />
          </select>
          {organisation && (
            <>
            <label htmlFor="isFirstAid">Is this course a First Aid Course?</label>
            <select onChange={(e) => {
              if(e.target.value == 'Yes'){
              setIsFirstAid(true)
              } else {
                setIsFirstAid(false)
              }
            }}>
              <option>No</option>
              <option>Yes</option>
            </select>
            </>
          )}
          <label htmlFor="name">Course Name:</label>
          {category == 'Health & Safety' || category == 'Education' || category == 'Business Training' || category == 'Food Hygiene' ? (
            <input type="text" placeholder="Please Enter a Course name" name="name" value={name} onChange={e => setName(e.target.value)} required />
          ) : category == 'First Aid' || isFirstAid ? (
            <select onChange={e => setName(e.target.value)}>
              <option value=''>Please select a First Aid Course</option>
              <option>First Aid at Work</option>
              <option>Emergency First Aid at Work</option>
              <option>Paediatric First Aid at Work</option>
              <option>First Aid at Work Requal</option>
              <option>Basic Life Support</option>
              <option>Mental Health First Aid</option>
            </select>
          ) : (
            <input type="text" placeholder="Please Enter a Course name" name="name" value={name} onChange={e => setName(e.target.value)} required />
          )}

         
          <label htmlFor="date">Course Start Date:</label>
          <input type="date" id="courseStartDate" onChange={e => { if (e.target.value.length > 10) { return 'error' }; setStartDate(e.target.value) }} name="startDate" value={startDate} />
          <label htmlFor="date">Course End Date:</label>
          <input type="date" id="courseEndDate" onChange={e => { setEndDate(e.target.value) }} name="endDate" value={endDate} />
          <label htmlFor="spaces">Max Spaces:</label>
          <input type="spaces" id="courseSpaces" onChange={e => { setSpaces(e.target.value) }} name="spaces" value={spaces} />
          <label htmlFor="time">What time does the course start?</label>
          <input name="time" onChange={e => setTime(e.target.value)} required />
          <label htmlFor="qualification">What qualification level is this course? (if applicable)</label>
          <input type="text" name="qualification" placeholder="Please enter course qualification (e.g. Level 2)" onChange={e => { setQualification(e.target.value) }} value={qualification} />

          <label htmlFor="price">How much does the course cost? (exc. VAT)</label>
          <input type="number" name="price" placeholder="Please enter a course cost (e.g. 49.95)" onChange={e => { setPrice(e.target.value) }} value={price} required />

          <label htmlFor="location">Location:</label>
          <input type="text" placeholder="Please Enter a Course location" name="location" value={location} onChange={e => setLocation(e.target.value)} required />

          <button className="btn btn-green" type="submit">Add Course</button>
        </form>
      </div>
    </div>

  )
}

AddCourseForm.propTypes = {
  addCourse: PropTypes.func.isRequired,
}

export default connect(null, { addCourse })(AddCourseForm)
