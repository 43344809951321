import React from 'react';
import PropTypes from 'prop-types';
import './ContactBar.css';
import ContactBarItem from './ContactBarItem';
import emailIcon from '../../../img/email.svg';
import phoneIcon from '../../../img/phone.svg';

const ContactBar = props => {
  return (

    <div className="contactBar">
      <ContactBarItem text="info@orangeboxtraining.com" icon={emailIcon} />
      <ContactBarItem text="01429&nbsp;717000" icon={phoneIcon} />
      <ContactBarItem text="Proudly powered by" linkText="Imray Digital" link="https://imraydigital.co.uk" icon={null} />
    </div>
  )
}

ContactBar.propTypes = {

}

export default ContactBar
