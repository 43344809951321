import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import logo from '../../img/logo.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown/NavbarDropdown';
import ContactBar from './ContactBar/ContactBar';
import Caret from '../../img/caret.svg';


const Navbar = ({ auth: { isAuthenticated, loading, user } }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggleCaret, setToggleCaret] = useState('rotate(0deg)');

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (toggleCaret === 'rotate(0deg)') {
      setToggleCaret('rotate(180deg)')
    } else {
      setToggleCaret('rotate(0deg)')
    }
  }

  const authNavLinks = (
    <ul>
      <NavLink exact to="/courses" activeClassName="active-link">
        <li>View Courses</li>
      </NavLink>
      <div className="account-item" onClick={() => { toggleDropdown(); }}>
        <img src={user ? user.avatar : ''}></img>
        <img src={Caret} alt="" id="caret" style={{ transform: toggleCaret }} />
      </div>
    </ul >
  );

  const guestNavLinks = (
    <ul>
      <NavLink exact to="/courses" activeClassName="active-link">
        <li>View Courses</li>
      </NavLink>
      <NavLink exact to="/register" activeClassName="active-link">
        <li>Register</li>
      </NavLink>
      <NavLink exact to="/login" activeClassName="active-link">
        <li>Login</li>
      </NavLink>
    </ul>
  );


  return (
    <section className="navSection">
      <div className="navbarTop">
        <ContactBar />
      </div>
      <nav>
        <NavLink exact to="/" >
          <img src={logo} alt="Logo" />
        </NavLink>
        {!loading && isAuthenticated ? authNavLinks : guestNavLinks}
      </nav>

      {dropdownOpen ? <Dropdown toggleDropdown={toggleDropdown} /> : null}

    </section>
  )
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Navbar);
