import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
//import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { getAllUsers } from '../../actions/users';
import './DashboardAdminUsers.css';

const DashboardAdminUsers = ({ getAllUsers, users: { users } }) => {

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers])

  return (
    <div className="dashboardAdminUsers">
      {users.map(user => {
        return (

          <div className="dashboardAdminUsers__item">
            <div className="dashboardAdminUsers__item-header">
              {user.isAdmin && <div className="isAdmin">Admin</div>}
            </div>
            <div className="dashboardAdminUsers__item-body">
              <img src={user.avatar} alt="" />
              <h3>{user.name}</h3>
              <p>{user.email}</p>
              <p>{user.contact}</p>
              {user.address && <p>{user.address}</p>}
            </div>

          </div>

        )
      })}
    </div>
  )
}

DashboardAdminUsers.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps, { getAllUsers })(DashboardAdminUsers)
