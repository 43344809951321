import React from 'react'
import './Hero.css';

const Hero = ({ title, bgImage, datePicker, typeSearch, setDate, setType, bgOverlay }) => {
  return (
    <div className="heroBg" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="hero" style={{backgroundColor: !bgOverlay && 'transparent'}}>
        <h1>{title}</h1>
        {datePicker && (
          <div className="heroDataContainer">
            <label htmlFor="date">Search by date:</label>
            <input type="date" name="date" onChange={(e) => {
              setDate(new Date(e.target.value).toLocaleDateString())
            }} />
          </div>
        )}
        {typeSearch && (
          <div className="heroDataContainer">
            <label htmlFor="type">Search by type:</label>
            <select name="type" onChange={(e) => {
              setType(e.target.value)
            }}>
              <option default>Please select a course type</option>
              <option>First Aid at Work</option>
              <option>Emergency First Aid at Work</option>
              <option>Paediatric First Aid at Work</option>
              <option>First Aid at Work Requal</option>
              <option>Basic Life Support</option>
              <option>Mental Health First Aid</option>
            </select>
          </div>
        )}
      </div>
    </div>
  )
}

export default Hero
