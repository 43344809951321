import React, { useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { deleteBooking, updateBooking } from '../../actions/course';
import { loadUser } from '../../actions/auth';
import DeleteSVG from '../svg/DeleteSVG';
import EditSVG from '../svg/EditSVG';

const DashboardBookingItem = ({ item, auth: { user }, loadUser, deleteBooking, updateBooking, adminPage, bookedBy, updateUsers }) => {

  const [edit, setEdit] = useState(false);

  const [attendeeName, setAttendeeName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [PONumber, setPONumber] = useState('');

  const bookingData = {}
  if (attendeeName) bookingData.attendeeName = attendeeName;
  if (contact) bookingData.contact = contact;
  if (email) bookingData.email = email;
  if (address) bookingData.address = address;
  if (company) bookingData.company = company;
  if (PONumber) bookingData.PONumber = PONumber;

  //  let bookings = auth.user.bookings;

  /*
  useEffect(() => {
    loadUser();
  }, [user])
*/
  return (

    <div className="dashboardBooking" key={item._id}>

      {!edit ? (
        <Fragment>
          <div className="dashboardBooking__header">
            {!adminPage ? <h5>You completed a booking:</h5> : `Booking made by: ${bookedBy}`}
          </div>
          <ul className="dashboardBooking__details">
            <li className="bookingDetailsHeader">{item.courseName}</li>
            <li>{new Date(item.courseDate).toLocaleDateString()}</li>
            <li>{item.attendeeName}</li>
            <li>{item.contact}</li>
            <li><strong>User Email: </strong>{item.email}</li>
            <li>{item.invoiceEmail && <><strong>Invoice Email: </strong>{item.invoiceEmail}</>}</li>
            <li>{item.company}</li>
            <li>{item.address}</li>
            <li>PON: {item.PONumber}</li>
          </ul>
          <div className="dashboardBooking__footer">
            <Link to={`/courses/${item.courseID}`}><button className="btn btn-green">View Course</button></Link>
            {user.isAdmin && <button className="btn btn-delete" onClick={async () => {

              try {
                await deleteBooking(item.courseID, item._id, item.user);
              } catch (error) {

              }

              try {
                loadUser();
              } catch (error) {

              }

              if (updateUsers) {
                try {
                  updateUsers();
                } catch (error) {

                }
              }
            }}>
              <DeleteSVG />
            </button>}
            {!edit ?
              <button className="btn" onClick={() => setEdit(!edit)}>
                <EditSVG />
              </button> : null}
          </div>
        </Fragment>

      ) : (

          <div className="dashboardBooking__details__edit">
            <input type="text" name="attendeeName" value={attendeeName} placeholder={item.attendeeName} onChange={e => { setAttendeeName(e.target.value) }} />
            <input type="number" name="contact" value={contact} placeholder={item.contact} onChange={async e => { await setContact(e.target.value); }} />
            <input type="email" name="email" value={email} placeholder={item.email} onChange={e => { setEmail(e.target.value) }} />
            <input type="text" name="company" value={company} placeholder={item.company} onChange={e => { setCompany(e.target.value) }} />
            <input type="text" name="address" value={address} placeholder={item.address} onChange={e => { setAddress(e.target.value) }} />
            <input type="text" name="PONumber" value={PONumber} placeholder={item.PONumber ? item.PONumber : "Enter your PO Number here"} onChange={e => { setPONumber(e.target.value) }} />
            <div className="dashboardBooking__details__edit-btn">
              <button className="btn btn-green" onClick={async () => {

                try {
                  await updateBooking(item.courseID, item._id, bookingData);
                } catch (error) {

                }

                try {
                  await loadUser();

                } catch (error) {

                }

                setEdit(!edit);

              }}>Save</button>
              <button className="btn btn-gradient" onClick={() => setEdit(!edit)}>Close Editor</button>
            </div>
          </div>

        )
      }
    </div >
  )
}

DashboardBookingItem.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { deleteBooking, updateBooking, loadUser })(DashboardBookingItem)
