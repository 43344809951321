import React, {useState} from 'react'
import PropTypes from 'prop-types'
//Redux
import { connect } from 'react-redux';
//CSS
import './DashboardAdminViewOrganisations.css';

const DashboardAdminViewOrganisations = ({categories, users}) => {
    const [activeOrganisation, setActiveOrganisation] = useState('');

    const filteredCategories = categories.filter(category => {
        if(!category.open){
            return category;
        }
    })

    const filteredUsers = users.filter(user => {
        if(user.organisation == activeOrganisation){
            return user;
    }
    })

  return (
    <div className="dashboardAdminViewOrganisations">
        <div className="organisationsFilter">
            <p>Filter by Organisation: </p>
            <select onChange={(e)=>{
                setActiveOrganisation(e.target.value)
            }}>
            {filteredCategories.map(category=>{
                return (
                    <option>
                        {category.name}
                    </option>
                )
            })}
            </select>
        </div>
        <div className="organisationsMembers">
            {filteredUsers.map(user=>{
                return (
                   <div className="dashboardAdminUsers__item">
                       <div className="dashboardAdminUsers__item-header">
              {user.isAdmin && <div className="isAdmin">Admin</div>}
            </div>
            <div className="dashboardAdminUsers__item-body">
              <img src={user.avatar} alt="" />
              <h3>{user.name}</h3>
              <p>{user.organisation}</p>
              <p>{user.email}</p>
              <p>{user.contact}</p>
              {user.address && <p>{user.address}</p>}
            </div>

                   </div>
                )
            })}
        </div>
      
    </div>
  )
}

DashboardAdminViewOrganisations.propTypes = {
    categories: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    categories: state.course.categories,
    users: state.users.users
})

export default connect(mapStateToProps)(DashboardAdminViewOrganisations)