import React from 'react';
import './CourseBookDetailsHeaderItem.css';

const CourseBookDetailsHeaderItem = (props) => {
  return (
    <div className="courseBook__details-header-item">
      <p className="courseBook__details-header-item__title">{props.type}</p>
      <p className="courseBook__details-header-item__body">{props.value}</p>
    </div>
  )
}

export default CourseBookDetailsHeaderItem
