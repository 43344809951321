import {
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_COURSES,
  COURSE_ERROR,
  POST_COURSE,
  DELETE_COURSE,
  GET_COURSE,
  CREATE_BOOKING,
  DELETE_BOOKING,
  BOOKING_ERROR,
  UPDATE_USER,
  ADD_CATEGORY,
  ADD_CATEGORY_ERROR,
  CLEAR_CURRENT_CATEGORY,
  CLEAR_CATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  UPDATE_BOOKING
} from '../actions/types';


const initialState = {
  courses: [],
  categories: [],
  course: null,
  currentCategory: null,
  loading: true,
  error: {}
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COURSES:
      return {
        ...state,
        courses: payload,
        loading: false
      }
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false
      }
    case GET_CATEGORY:
      return {
        ...state,
        currentCategory: payload,
        loading: false
      }
    case CLEAR_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: null,
        loading: false
      }
    case CLEAR_CATEGORY_ERROR:
      return {
        ...state
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(category => category._id !== payload)
      }
    case DELETE_CATEGORY_ERROR:
      return {

      }
    case GET_COURSE:
      return {
        ...state,
        course: payload,
        loading: false
      }
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload],
        loading: false
      }
    case POST_COURSE:
      return {
        ...state,
        courses: [...state.courses, payload],
        loading: false
      }
    case CREATE_BOOKING:
      return {
        ...state,
        courses: [...state.courses, payload],
        loading: false
      }
    case UPDATE_USER:
      return {
        ...state,
        auth: {
          user: payload
        }
      }
    case DELETE_BOOKING:
      return {
        ...state
      }
    case UPDATE_BOOKING:
      return {
        ...state,

        loading: false
      }
    case DELETE_COURSE:
      return {
        ...state,
        courses: state.courses.filter(course => course._id !== payload),
        loading: false
      }
    case COURSE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case BOOKING_ERROR:
    case ADD_CATEGORY_ERROR:
    default:
      return state;
  }
}