import axios from 'axios';
import { setAlert } from './alert';
import { ADD_CATEGORY, ADD_CATEGORY_ERROR, DELETE_CATEGORY, DELETE_CATEGORY_ERROR, GET_CATEGORIES, GET_CATEGORIES_ERROR, GET_CATEGORY, CLEAR_CURRENT_CATEGORY, CLEAR_CATEGORY_ERROR, GET_COURSES, COURSE_ERROR, POST_COURSE, DELETE_COURSE, GET_COURSE, CREATE_BOOKING, BOOKING_ERROR, DELETE_BOOKING, UPDATE_BOOKING } from './types';

// Get Courses

export const getCourses = () => async dispatch => {

  try {
    const res = await axios.get('/api/courses');

    dispatch({
      type: GET_COURSES,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: COURSE_ERROR,
      payload: { msg: error }
    })

  }

}

// Add courses

export const addCourse = (formData, message) => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {

    const res = await axios.post('/api/courses', formData, config);

    dispatch({
      type: POST_COURSE,
      payload: res.data
    })

    dispatch(setAlert(message, 'success'));

  } catch (error) {
    dispatch({
      type: COURSE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })
  }

}

// Get Course

export const getCourse = (id) => async dispatch => {


  try {
    const res = await axios.get(`/api/courses/${id}`);

    dispatch({
      type: GET_COURSE,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })
  }


}

// Delete Course

export const deleteCourse = (id, message = 'Course removed', type = 'danger', timeout = 2000) => async dispatch => {

  try {
    const res = await axios.delete(`/api/courses/${id}`);

    dispatch({
      type: DELETE_COURSE,
      payload: id
    });

    dispatch(setAlert(message, type, timeout))
  } catch (error) {
    dispatch({
      type: COURSE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })

  }

}

// Add booking to course

export const createBooking = (courseID, bookingData) => async dispatch => {


  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {

    // Add booking to course
    const res = await axios.put(`/api/courses/${courseID}`, bookingData, config);


    dispatch({
      type: CREATE_BOOKING,
      payload: res.data
    })

    dispatch(setAlert(`Booking Succesfully Created`, 'success', 5000, '/dashboard/bookings', 'View Bookings'));

    dispatch(getCourse(courseID));

  } catch (error) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: error, status: error }
    });
    dispatch(setAlert('There was an error with the booking, please try again.', 'danger', 3000));

    console.error(error.message)

  }

}


// Delete booking from course

export const deleteBooking = (courseID, bookingID, userID, message = 'Booking cancelled', type = 'danger', timeout = 2000) => async dispatch => {

  try {
    const res = await axios.delete(`/api/courses/${courseID}/${bookingID}/${userID}`);

    dispatch({
      type: DELETE_BOOKING,
      payload: res.data
    });


    dispatch(setAlert(message, type, timeout));

  } catch (error) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: error }
    })

  }
}

// Get Course Categories

export const getCategories = () => async dispatch => {
  try {

    const res = await axios.get('/api/categories');

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: { msg: error }
    })
  }
}

// Get Single Course Category

export const getCategory = (id) => async dispatch => {
  try {

    const res = await axios.get(`/api/categories/${id}`);

    dispatch({
      type: GET_CATEGORY,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      payload: { msg: error }
    })
  }
}


export const addCategory = (formData, message) => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {

    const res = await axios.post('/api/categories', formData, config);

    dispatch({
      type: ADD_CATEGORY,
      payload: res.data
    })

    dispatch(setAlert(message, 'success'));

  } catch (error) {
    dispatch({
      type: ADD_CATEGORY_ERROR,
      payload: { msg: error }
    })
  }

}

export const clearCurrentCategory = () => async dispatch => {
  try {
    dispatch({
      type: CLEAR_CURRENT_CATEGORY
    })
  } catch (error) {
    dispatch({
      type: CLEAR_CATEGORY_ERROR,
      payload: { msg: error }
    })
  }
}

export const deleteCategory = (id) => async dispatch => {

  const res = await axios.delete(`/api/categories/${id}`);

  try {
    dispatch({
      type: DELETE_CATEGORY,
      payload: id
    })

    dispatch(setAlert(`Category with ID: ${id} was successfully removed`, 'success'))
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_ERROR,
      payload: { msg: error }
    })
  }
}

export const updateBooking = (courseID, bookingID, dataObj) => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const res = axios.put(`/api/courses/${courseID}/${bookingID}`, dataObj, config)

  try {
    dispatch({
      type: UPDATE_BOOKING,
      payload: res.data
    })

    dispatch(setAlert('User updated successfully! Click View Course to see changes', 'success'));
  } catch (error) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: error }
    })
  }

}