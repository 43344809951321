import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../Spinner'
import { getCourse } from '../../actions/course';
import CourseBook from './Subcomponents/CourseBook';
import CourseOverview from './CourseOverview';
import './Course.css';

const Course = ({ getCourse, auth: { user }, course: { course, loading }, match }) => {

  useEffect(() => {
    getCourse(match.params.id);
  }, [getCourse, match.params.id]);

  const options = {
    year: 'numeric', month: 'long', day: 'numeric'
  };

  const [showInfo, setShowInfo] = useState(true);
  //const [redirect, setRedirect] = useState(false);

  return loading || course === null ? <Spinner /> : (

    <section className="courseContainer">
      <div className="courseTitle">
        <div className="courseTitleLeft">
          <h1>{course.name}</h1>
          <h3>{`${new Date(course.startDate).toLocaleDateString('en-gb', options)} - ${new Date(course.endDate).toLocaleDateString('en-gb', options)}`}</h3>
          <h3><strong>Course Time: </strong>{course.time} - 4:00</h3>
          <h3><strong>Spaces remaining: </strong>{course.spaces}</h3>
          <h3><strong>Course Cost: </strong>£{course.price} + VAT</h3>
        </div>
        <div className="courseTitleRight">
          <CourseOverview courseType={course.name} />
        </div>
      </div>
      <CourseBook courseID={course._id} course={course} />
      {
        user.isAdmin ? (
          <div className="courseAttendees">
            <h3>Course Attendees</h3>
            <button id="sensitiveInfoToggle" className='btn-green' style={{ alignSelf:'flex-end', width: '130px', padding: '5px', marginBottom: '10px'}} onClick={() => setShowInfo(!showInfo)}>{showInfo ? 'Click to hide sensitive info' : 'Click to show sensitive info'}</button>
            <div className="courseAttendeesContainer">

              {course.attendees.map(attendee => (
                <div key={attendee._id} className="dashboardAdminUsers__item">
                  <div className="dashboardAdminUsers__item-header">
                    <p>Booking made by:</p>
                    <p>{attendee.userName}</p>
                  </div>
                  <div className="dashboardAdminUsers__item-body">
                    <h3>{attendee.attendeeName}</h3>
                    {showInfo && (
                    <div className='showBookingInfo'>
                      <p><strong>Invoice: </strong>{attendee.invoiceEmail ? attendee.invoiceEmail : attendee.email }</p>
                      <p>{attendee.contact}</p>
                      {attendee.company ? <p>{attendee.company}</p> : null}
                      <p>{attendee.address}</p>
                      <p>Booking created: {new Date(attendee.date).toLocaleDateString()}</p>
                      {attendee.PONumber ? (
                        <p>PO Number: {attendee.PONumber}</p>
                      ): (
                        <p id="poNum">PO Number:</p>
                      )}
                    </div>
                    )}

                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null
      }
    </section >

  )
}

Course.propTypes = {
  getCourse: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth
})

export default connect(mapStateToProps, { getCourse })(Course)
