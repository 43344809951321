import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createBooking } from '../../../actions/course';
import './CourseBook.css';
import CourseBookDetailsHeaderItem from './CourseBookDetailsHeaderItem';



const CourseBook = ({ createBooking, courseID, auth, course }) => {

  //const [open, setOpen] = useState(false);

  const [attendeeName, setAttendeeName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState(auth.user.email);
  const [invoiceEmail, setInvoiceEmail] = useState(auth.user.email);
  const [company, setCompany] = useState(auth.user.organisation);
  const [address, setAddress] = useState('');
  //const [courseInfo, setCourseInfo] = useState({ ...course });

  let courseInfo = course;

  const bookingData = {
    attendeeName,
    contact,
    email,
    invoiceEmail,
    address,
    company,
    bookingType: 'Individual',
    courseInfo
  }
/*
  const resetFields = () => {
    setAttendeeName('');
    setContact('');
    setEmail('');
    setCompany('');
    setAddress('');
  }
  */

  return (
    <div className="courseBook">

      <div className="courseBook__details">
        <div className="courseBook__details-header">
          <CourseBookDetailsHeaderItem type={"Location"} value={course.location} />
          <CourseBookDetailsHeaderItem type={"Price"} value={`£${course.price} + VAT`} />
          <CourseBookDetailsHeaderItem type={"Qualification"} value={course.qualification ? course.qualification : 'N/A'} />
        </div>
        <div className="courseBook__details-body">
          <div className="courseBook__details-body-img" style={{ backgroundImage: `url(${course.image})` }}>
          </div>
        </div>
      </div>


      <div className="courseBook__form">
        <div className="form-container">
          <div className="form">
            <p style={{ color: 'rgba(0,0,0,0.4)' }}>Please fill in your details below. If you are making a booking on behalf of another individual, input their details instead.</p>
            <form action="">
              <div className="form-field">
                <label htmlFor="name">Attendee Name:</label>
                <input type="text" name="attendeeName" value={attendeeName} placeholder="Please enter the name of the attendee" onChange={e => { setAttendeeName(e.target.value) }} required/>
              </div>
              <div className="form-field">
                <label htmlFor="contact">Contact Number:</label>
                <input type="number" name="contact" value={contact} placeholder="Please enter a contact number" onChange={e => { setContact(e.target.value) }} required />
              </div>
              <div className="form-field">
                <label htmlFor="email">Contact Email:</label>
                <input type="email" value={email} name="attendeeEmail" placeholder="Please enter the email address of the attendee" onChange={e => { setEmail(e.target.value) }} required/>
              </div>
              <div className="form-field">
                <label htmlFor="email">Invoice Email:</label>
                <input type="email" name="invoiceEmail" value={invoiceEmail} placeholder="Please enter a valid email address" onChange={e => { setInvoiceEmail(e.target.value) }} required/>
              </div>
              <div className="form-field">
                <label htmlFor="company">Company:</label>
                <input type="text" name="company" value={company} placeholder="If individual, please state 'Individual'" onChange={e => { setCompany(e.target.value) }} required/>
              </div>
              <div className="form-field">
                <label htmlFor="address">Address:</label>
                <input type="text" name="address" value={address} placeholder="Please enter a suitable address and postcode" onChange={e => { setAddress(e.target.value) }}  required/>
              </div>
              {!auth.isAuthenticated ? <h2>Please <Link to="/login">login</Link> or <Link to="/register">create an account</Link> to book a space on this course.</h2> : course.spaces > 0 ? <button className="btn btn-span btn-green" onClick={(e) => { e.preventDefault(); createBooking(courseID, bookingData); }}>Book</button> : <h2>Sorry. This course has reached it's capacity. Please try again later or choose another course.</h2>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

CourseBook.propTypes = {

  createBooking: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { createBooking })(CourseBook);