import axios from 'axios';
import { setAlert } from './alert';
import { GET_ALL_USERS } from './types';


// Get all users

export const getAllUsers = () => async dispatch => {

  const res = await axios.get('/api/users/all')


  dispatch({
    type: GET_ALL_USERS,
    payload: res.data
  })

}