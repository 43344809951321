import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourses, getCategories, deleteCourse } from '../../../actions/course';
//import SingleCourse from './SingleCourse';
import './Courses.css';
import Spinner from '../../Spinner';
import { Link } from 'react-router-dom';
//import OBImg from '../../../img/orangebox-image.jpeg';
import Hero from './Hero';
import bgImage from '../../../img/cover.jpeg';
import firstAidIcon from '../../../img/first-aid-kit.svg';
import foodIcon from '../../../img/washing-hands.svg';
import businessIcon from '../../../img/customer-support.svg';
import educationIcon from '../../../img/mortarboard.svg';
import hsIcon from '../../../img/hard-hat.svg';

const Courses = ({ auth, getCourses, getCategories, course: { courses, loading, categories } }) => {


  useEffect(() => {
    getCourses();
    getCategories();
  }, []);

  /*
  let filteredCourses = courses.filter((course) => {
    return Math.abs(new Date(course.startDate).getTime()) - (new Date().getTime() / 36e5)
  })
*/

  if (loading) {
    return <Spinner />
  } else {

    return (

      <div className="wrapper" >
        <Hero title="Find a Course" bgImage={bgImage} bgOverlay />
        <div className="title courseCategoriesList__title">
          <h2>Our Open Courses</h2>
        </div>

        {
          categories.length > 0 ?
            <div className="courseCategoriesList__body">
              {categories.map(category => {

                if(category.open){
                return (
                  <Link to={`/categories/${category._id}`}>
                    <div className="coursesCategoryItem" style={{ backgroundColor: category.colorCode }}>
                      <div className="circle1" ></div>
                      <div className="circle2" ></div>
                      <div className="circle3" ></div>
                      <div className="coursesCategoryItemTitle">
                        {category.name === 'First Aid' ? (
                          <img src={firstAidIcon} />
                        )
                      : category.name === 'Food Hygiene' ? (
                        <img src={foodIcon} />
                      ) : category.name === 'Business Training' ? (
                        <img src={businessIcon} />
                      ) : category.name === 'Education' ? (
                        <img src={educationIcon} />
                      ) : category.name === 'Health & Safety' ? (
                        <img src={hsIcon} />
                      ) : null}
                        {category.name}
                      </div>
                      <div className="coursesCategoryItemFooter">
                        <p>{category.courses.length == 0 || category.courses.length > 1 ? `${category.courses.length} Courses` : `${category.courses.length} Course`} </p>
                        {!category.open && <p style={{ marginLeft: '10px' }}>Staff Only</p>}
                      </div>
                    </div>
                  </Link>
                )
                }
              })
              }
            </div> : 'Categories will appear here when added'

        }

        <div className="title courseCategoriesList__title">
          {auth.user.organisation !== 'Individual' || auth.user.isAdmin && <h2>Your Organisation Courses</h2>}
        </div>

        {
          categories.length > 0 &&
            <div className="courseCategoriesList__body">
              {categories.map(category => {

                if(!category.open && auth.user.isAdmin || !category.open && category.name == auth.user.organisation){
                return (
                  <Link to={`/categories/${category._id}`}>
                    <div className="coursesCategoryItem" style={{ backgroundColor: category.colorCode }}>
                      <div className="circle1" ></div>
                      <div className="circle2" ></div>
                      <div className="circle3" ></div>
                      <div className="coursesCategoryItemTitle">
                        {category.name}
                      </div>
                      <div className="coursesCategoryItemFooter">
                        <p>{category.courses.length == 0 || category.courses.length > 1 ? `${category.courses.length} Courses` : `${category.courses.length} Course`} </p>
                        {!category.open && <p style={{ marginLeft: '10px' }}>Staff Only</p>}
                      </div>
                    </div>
                  </Link>
                )
                }
              })
              }
            </div>

        }

        { /*
        <div className="courses">
          <div className="title courseCategoriesList__title">
            <h2>Upcoming Courses</h2>
          </div>
          {filteredCourses < 1 ? <div>Upcoming courses will appear here</div> : filteredCourses.map(course => {

            course.startDate = course.startDate.slice(0, 10);
            return <SingleCourse key={`${course._id}${num++}`} imgPath={OBImg} course={course} auth={auth} />

          })}
        </div>

        */}

      </div>)
  }
}

Courses.propTypes = {
  getCourses: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  deleteCourse: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth
});

export default connect(mapStateToProps, { getCategories, getCourses, deleteCourse })(Courses)
