import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import userIcon from '../../img/user.png';
import mobileIcon from '../../img/mobile.png';
import emailIcon from '../../img/email.png';
import padlockIcon from '../../img/padlock.png';
import addressIcon from '../../img/home.svg';


const Register = ({ setAlert, register, isAuthenticated, categories }) => {

  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    organisation: '',
    email: '',
    address: '',
    password: '',
    password2: ''
  });

  const { name, contact, organisation, email, address, password, password2 } = formData;

  const onChange = e => {
    if(e.target.value !== 'Please select your organisation if applicable'){
    setFormData({ ...formData, [e.target.name]: e.target.value })
    } else {
      setFormData({ ...formData, [e.target.name]: '' })
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match.', 'danger', 2000)
    } else {
      register({
        name,
        contact,
        organisation,
        email,
        address,
        password
      })
    }
  }

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <div className="form-container">
      <div className="form">
        <h2>Create a free account</h2>
        <form onSubmit={e => onSubmit(e)}>
          <div className="form-field">
            <img src={userIcon} alt="user-icon" />
            <input type="text" placeholder="Please Enter your Full Name" name="name" value={name} onChange={e => onChange(e)} required/>
          </div>
          <div className="form-field">
            <img src={mobileIcon} alt="mobile-icon" />
            <input type="number" placeholder="Please Enter your Contact Number" name="contact" value={contact} onChange={e => onChange(e)} required/>
          </div>
          <div className="form-field">
            <img src={mobileIcon} alt="mobile-icon" />
            <select name="organisation" onChange={e => onChange(e)} required>
              <option default>
             Please select your organisation if applicable
              </option>
              {categories.map(category => {
                  if(!category.open){
                    return (
                      <option>
                        {category.name}
                      </option>
                    )
                  }
              })}
              <option>
                Other/Individual
              </option>
            </select>
          </div>
          <div className="form-field">
            <img src={emailIcon} alt="email-icon" />
            <input type="email" placeholder="Please Enter your Email Address" name="email" value={email} onChange={e => onChange(e)} required/>
          </div>
          <div className="form-field">
            <img src={addressIcon} alt="location-icon" />
            <input type="text" placeholder="Please Enter a suitable address and postcode" name="address" value={address} onChange={e => onChange(e)} required/>
          </div>
          <div className="form-field">
            <img src={padlockIcon} alt="padlock-icon" />
            <input type="password" placeholder="Please Enter your password" name="password" value={password} onChange={e => onChange(e)} required/>
          </div>
          <div className="form-field">
            <img src={padlockIcon} alt="padlock-icon" />
            <input type="password" placeholder="Please Confirm your password" name="password2" value={password2} onChange={e => onChange(e)} required />
          </div>
          <button className="btn btn-gradient btn-span" type="submit">Register</button>
          <p>Already have an account? <Link to="/login">Log in</Link></p>

        </form>
      </div>
    </div>
  )
}

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  categories: PropTypes.object.isRequired
}

const mapStateToProps = state => (
  {
    isAuthenticated: state.auth.isAuthenticated,
    categories: state.course.categories
  }
)

export default connect(mapStateToProps, { setAlert, register })(Register)
