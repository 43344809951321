import React from 'react';
import './Spinner.css';
import loadingGif from '../img/loading2.gif';

const Spinner = () => {
  return (
    <div className="spinner">
      <img src={loadingGif} />
    </div>
  )
}

export default Spinner
