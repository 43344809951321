import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import emailIcon from '../../img/email.png';
import padlockIcon from '../../img/padlock.png';


const Login = ({ login, isAuthenticated }) => {

  const [formData, setFormData] = useState({

    email: '',
    password: '',

  });

  const { email, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  }

  // Redirect if logged in

  if (isAuthenticated) {
    return <Redirect to="/courses" />
  }



  return (
    <div className="form-container">
      <div className="form">
        <h2>Login</h2>
        <form onSubmit={e => onSubmit(e)}>
          <div className="form-field">
            <img src={emailIcon} alt="user-icon" />
            <input type="email" placeholder="Please Enter your Email Address" name="email" value={email} onChange={e => onChange(e)} required />
          </div>
          <div className="form-field">
            <img src={padlockIcon} alt="user-icon" />
            <input type="password" placeholder="Please Enter your password" name="password" value={password} onChange={e => onChange(e)} required />
          </div>
          <button className="btn btn-gradient btn-span" type="submit">Login</button>
          <p>Don't have an account yet? <Link to="/register">Register</Link></p>
        </form>
      </div>
    </div>
  )

}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => (
  {
    isAuthenticated: state.auth.isAuthenticated
  }
)

export default connect(mapStateToProps, { login })(Login)
