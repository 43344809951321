import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Alert.css';

const Alert = ({ alerts }) => alerts !== null && alerts.length > 0 && alerts.map(alert => {
  return (

    <div key={alert.id} className={`alert alert-${alert.alertType}`}>
      {alert.msg}
      {alert.link && alert.linkText ? <Link to={alert.link}>{alert.linkText}</Link> : null}
    </div>

  )
})

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  alerts: state.alert
})

export default connect(mapStateToProps)(Alert);
