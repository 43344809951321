import React, { useEffect } from 'react';
import './App.css';
import Navbar from './Components/Layout/Navbar';
import Login from './Components/auth/Login';
import Register from './Components/auth/Register';
import Dashboard from './Components/Dashboard/Dashboard';
import Courses from './Components/Layout/Courses/Courses';
import Course from './Components/Course/Course';
import Landing from './Components/Layout/Landing';
import SingleCategory from './Components/Layout/Courses/SingleCategory';
import AddCourseForm from './Components/Layout/Courses/AddCourseForm';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Redux
import Alert from './Components/Layout/Alert';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

//Microsoft Graph


if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {

  useEffect(() => {
    store.dispatch(loadUser());
  }, [])


  return (

    <Provider store={store}>
      <Router>
        <Navbar />
        <div className="container">
          <div className="alert-container">
            <Alert />
          </div>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/add-course" component={AddCourseForm} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/courses/:id" component={Course} />
            <Route exact path="/categories/:id" component={SingleCategory} />
            <Route path="/dashboard" component={Dashboard} />

          </Switch>
        </div>

      </Router>
    </Provider>

  )
}

export default App;
