import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourses, getCategories } from '../../actions/course';
import { Link } from 'react-router-dom'
import './Landing.css';
import Spinner from '../Spinner';

const Landing = ({ auth: { loading }, getCourses, getCategories }) => {

  useEffect(() => {
    getCourses();
    getCategories();
  }, []);

  if (loading) {
    return (
      <Spinner />
    )
  } else {
    return (
      <div className="landing-page">
        <div className="landing-page-header">
          <h1>Welcome to Orangebox Training Solutions</h1>
          <h2>Booking System</h2>
          <p>Enter our dedicated app to find a course</p>
          <Link to="/courses"><button className='btn-dark'>Enter</button></Link>
        </div>

      </div >
    )
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {getCourses, getCategories})(Landing);
