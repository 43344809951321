import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { deleteCourse, getCourses } from '../../actions/course';
import SingleCourse from '../Layout/Courses/SingleCourse';
import './DashboardAdminDeleteCourse.css';
import OBImg from '../../img/orangebox-image.jpeg';
import DeleteSVG from '../svg/DeleteSVG';
const DashboardAdminDeleteCourse = ({ auth, course: { courses }, deleteCourse, getCourses }) => {


  /* Get Courses

  1. Get Courses
  2. Display Course Items with settings icon - .map()
  3. Delete button in settings

  */

  useEffect(() => {
    getCourses();
  }, [getCourses]);


  /*
  let courseDate = new Date(courses.date);
  courseDate = courseDate.toString()
  courseDate = courseDate.slice(0, 15);
  */


  if (courses.length > 0) {

    return (
      <div className="dashboardAdminDeleteCourse">
        {courses.map(course => {
          return (
            <div className="dashboardAdminDeleteCourse__item">
              <SingleCourse key={`${course._id}`} imgPath={OBImg} course={course} auth={auth} />
              <div className="dashboardAdminDeleteCourse__item-footer">
                {/* <DashboardAdminSettings deleteCourse={deleteCourse} course={course} /> */}

                {<button onClick={() => { deleteCourse(course._id) }}>
                  Delete <DeleteSVG />
                </button>}
              </div>
            </div>
          )
        })}
      </div>
    )

  } else {
    return (
      <div className="dashboardAdminDeleteCourse">
        <p>Scheduled courses will appear here</p>
      </div>
    )
  }


}

DashboardAdminDeleteCourse.propTypes = {
  deleteCourse: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  course: state.course,
  auth: state.auth
})


export default connect(mapStateToProps, { deleteCourse, getCourses })(DashboardAdminDeleteCourse);
